import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import Content from '../Primitive/Content'

SwiperCore.use([ Autoplay ])

const OfferHero = ({ theme, category, sell, displayHeadline, images }) => {
  return (
    <div className={classNames('OfferHero', theme && `OfferHero-${theme}`)}>
      <Swiper
        className="OfferHero__Swiper"
        spaceBetween={0}
        slidesPerView={1}
        autoplay
        enabled={images.length > 1}
      >
        {images &&
          images.length > 0 &&
          images.map((image, i) => (
            <SwiperSlide key={`OfferSwiper-${i}`}>
              <picture className="OfferHero__Background">
                <source media="(min-width: 550px)" srcSet={image.url} />
                <img src={image.mobileUrl} alt={displayHeadline} />
              </picture>
            </SwiperSlide>
          ))}
      </Swiper>
      <Content width="large" center gutter className="OfferHero__Content">
        {category && <div className="OfferHero__Category">{category}</div>}
        <h2
          dangerouslySetInnerHTML={{ __html: displayHeadline }}
          className="OfferHero__Headline"
        />
        {sell && <p dangerouslySetInnerHTML={{ __html: sell }} className="OfferHero__Sell" />}
      </Content>
    </div>
  )
}

OfferHero.propTypes = {
  theme: PropTypes.string,
  images: PropTypes.array.isRequired,
  category: PropTypes.string,
  sell: PropTypes.string,
  displayHeadline: PropTypes.string.isRequired
}

export default withTheme(OfferHero)
