import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import withTheme from '../component/Theme/withTheme'
import Content from '../component/Primitive/Content'
import Dropdown from '../component/Primitive/Dropdown'
import VisuallyHidden from '../component/Primitive/VisuallyHidden'
import Icon from '../component/Primitive/Icon'
import SmartLink from '../component/Primitive/SmartLink'

const NavigationBroadsheet = ({
  theme,
  links,
  instanceName,
  logo,
  className,
  isLoggedIn,
  user
}) => {
  return (
    <div className={classNames('Navigation', theme && `Navigation-${theme}`, className)}>
      <Content className="Navigation__Container" center gutter width="large">
        <nav className="Navigation__Inner">
          <div className="Navigation__Links">
            <div className="Navigation__Home">
              <SmartLink target="_blank" href="https://www.broadsheet.com.au/" aria-label="Home">
                <img className="Navigation__Logo" src={logo} alt="Logo" />
              </SmartLink>
              <VisuallyHidden>
                <h1>{instanceName}</h1>
              </VisuallyHidden>
            </div>
            {links.length !== 0 && (<div className="Navigation__Links Navigation__Dropdown">
              <Dropdown
                items={links}
              />
            </div>)}
          </div>
          <AccessLogo />
          <div className="Navigation__User">
            {!isLoggedIn &&
              <SmartLink
                href="/login"
                className="js-track-link"
                data-event-name="click_login"
                data-position="navigation"
              >
                Log in
              </SmartLink>
            }
            {isLoggedIn && user && (
              <React.Fragment>
                <p>Hi {user.firstName}</p>
                {user.avatarUrl && (
                  <img className="Navigation__User__Avatar" width={35} height={35} src={user.avatarUrl} alt="avatar" />
                )}
              </React.Fragment>
            )}
          </div>
        </nav>
      </Content>
      <AccessLogo className="Navigation__AccessLogo--mobile" />
    </div>
  )
}

NavigationBroadsheet.propTypes = {
  theme: PropTypes.string,
  links: PropTypes.array,
  instanceName: PropTypes.string,
  logo: PropTypes.string,
  className: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  user: PropTypes.shape({
    avatarUrl: PropTypes.string,
    firstName: PropTypes.string
  })
}

export default withTheme(NavigationBroadsheet)

const AccessLogo = ({ className }) => (
  <SmartLink
    className={classNames('Navigation__AccessLogo', className)}
    href="/"
  >
    <Icon
      theme="broadsheet"
      type="AccessLogo"
      width={213}
      height={35}
      a11yText="Broadsheet"
    />
  </SmartLink>
)

AccessLogo.propTypes = {
  className: PropTypes.string
}
