const debug = require('debug')('braze')

export default (serviceLocator) =>
  async (apiUrl, method = 'GET') => {
    const instancePath = serviceLocator.instancePath

    debug(`Fetching data from /api/${apiUrl}`)

    let res = null
    let responseText = null

    try {
      res = await fetch(`${instancePath}api/${apiUrl}`, {
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        method: method
      })

      responseText = await res.text()

      let data = {}

      if (res.status === 200) {
        data = JSON.parse(responseText)
      } else if (window.Raven) {
        window.Raven.setExtraContext({ extra: { responseText } })
        window.Raven.captureException(
          new Error(`Error fetching data from site API - ${res.status}`)
        )
      } else {
        debug(`Error fetching data from site API - ${res.status} - ${responseText}`)
      }
      return { ...data }
    } catch (e) {
      if (window.Raven) {
        window.Raven.setExtraContext({ extra: { responseText } })
        window.Raven.captureException(
          new Error(`Error fetching data from site API - ${res.status} - ${e.message}`)
        )
        window.Raven.setExtraContext()
      }

      debug(`Error fetching data from site API - ${res.status} - ${e.message}`)
    }
  }
