import React from 'react'
import PropTypes from 'prop-types'

import withTheme from '../component/Theme/withTheme'

import NavigationBroadsheet from './NavigationBroadsheet'

const Navigation = ({ theme, ...other }) => {
  const Component = {
    broadsheet: (
      <NavigationBroadsheet
        {...other}
      />
    )
  }

  return Component[theme]
}

Navigation.propTypes = {
  theme: PropTypes.string
}

export default withTheme(Navigation)
