import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const LoadingSpinnerBroadsheet = ({ large }) => {
  return (
    <div className={classNames('Spinner', large && 'SpinnerLarge')}>
      <div className="SpinnerInner">
        {[ ...Array(10).keys() ].map((i) => (
          <div key={i} />
        ))}
      </div>
    </div>
  )
}

LoadingSpinnerBroadsheet.propTypes = {
  large: PropTypes.bool
}

export default LoadingSpinnerBroadsheet
