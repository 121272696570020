import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import IconButton from '../Primitive/IconButton'
import VisuallyHidden from '../Primitive/VisuallyHidden'

const OfferSharing = ({ theme, alignment, shareUrls, hydrated }) => {
  const copyRef = useRef(null)
  const [ copied, setCopy ] = useState(false)

  const copyTextToClipboard = (e) => {
    copyRef.current.select()
    document.execCommand('copy')
    setCopy(true)
    setTimeout(() => setCopy(false), 2000)
  }

  return (
    <div
      className={classNames(
        'OfferSharing',
        theme && `OfferSharing-${theme}`,
        alignment === 'Center' && 'centered'
      )}
    >
      {hydrated &&
        <VisuallyHidden>
          <input
            ref={copyRef}
            value={window.location.href}
          />
        </VisuallyHidden>}
      <div className="OfferSharing__Heading">Share</div>
      <div className="OfferSharing__Items">
        <IconButton
          small
          icon="Facebook"
          href={shareUrls.facebook}
          className={`OfferSharing__Item OfferSharing__Item-Facebook`}
          aria-label="Share on Facebook"
        />
        <IconButton
          small
          icon="Twitter"
          href={shareUrls.twitter}
          className={`OfferSharing__Item OfferSharing__Item-Twitter`}
          aria-label="Share on Twitter"
        />
        <span
          className={classNames(
            `OfferSharing__Item`,
            `OfferSharing__Item-Share`,
            copied && `OfferSharing__Item-Share--copied`
          )}
          onClick={(e) => copyTextToClipboard(e)}>
          <IconButton
            small
            icon="Share"
            aria-label="Share"
          />
        </span>
      </div>
    </div>
  )
}

OfferSharing.propTypes = {
  theme: PropTypes.string,
  alignment: PropTypes.string,
  shareUrls: PropTypes.object,
  hydrated: PropTypes.bool
}

export default withTheme(OfferSharing)
