import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'

SwiperCore.use([ Autoplay ])

const duplicatePartners = (partners, times) => {
  let result = []
  for (let i = 0; i < times; i++) {
    result = result.concat([ ...partners ])
  }
  return result
}

const PartnerCarousel = ({ partners, theme }) => {
  if (!partners || !partners.length) return null

  const partnersToShow = duplicatePartners(partners, 5)

  return (
    <div
      className={classNames(
        'PartnerCarousel',
        theme && `PartnerCarousel-${theme}`
      )}
    >
      <Swiper
        className="PartnerCarousel__Swiper"
        spaceBetween={0}
        slidesPerView="auto"
        centeredSlides
        allowTouchMove={false}
        loop
        speed={3000}
        freeMode
        autoplay={{
          delay: 1
        }}
      >
        {partnersToShow.map((partner, i) => (
          <SwiperSlide
            className="PartnerCarousel__Slide"
            key={`partner-image${i}`}
          >
            <a
              className="PartnerCarousel__Partner"
              target="_blank"
              href={partner.url}
            >
              <div className="PartnerCarousel__Image">
                <img src={partner.imageUrl} alt={partner.name || ''} />
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

PartnerCarousel.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string
    })
  ),
  theme: PropTypes.string
}

export default withTheme(PartnerCarousel)
