import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'

export class GridItemComponent extends PureComponent {
  render () {
    const { children, sizes, className } = this.props
    return (
      <div className={classNames(
        'grid__item',
        sizes && sizes.map(size => size),
        className
      )}>
        {children}
      </div>
    )
  }
}

GridItemComponent.displayName = 'GridItem'

GridItemComponent.propTypes = {
  children: PropTypes.node.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
}

class GridComponent extends PureComponent {
  render () {
    const { children, gutterless, gutter, vcentered, flex, refNode, className, flexTabletLandscape, reverseMobile, reverseTablet, theme } = this.props

    return (
      <div
        className={classNames(
          'grid',
          theme && `grid--${theme}`,
          gutterless && 'grid--gutterless',
          gutter && `grid--gutter-${gutter}`,
          flex && 'grid--flex',
          flexTabletLandscape && 'grid--flex-tablet-landscape',
          reverseTablet && 'grid--reverse-tablet',
          vcentered && 'grid--vcentered',
          reverseMobile && 'grid--reverse-mobile',
          className
        )}
        ref={refNode}
      >
        {children}
      </div>
    )
  }
}

GridComponent.displayName = 'Grid'

GridComponent.propTypes = {
  children: PropTypes.node,
  gutterless: PropTypes.bool,
  vcentered: PropTypes.bool,
  flex: PropTypes.bool,
  flexTabletLandscape: PropTypes.bool,
  reverseMobile: PropTypes.bool,
  reverseTablet: PropTypes.bool,
  refNode: PropTypes.func,
  className: PropTypes.string,
  gutter: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
  theme: PropTypes.string
}

const Grid = withTheme(GridComponent)
const GridItem = withTheme(GridItemComponent)

export { Grid, GridItem }
