import React from 'react'
import ResizeObserver from 'resize-observer-polyfill'

// Hook that measures the size of its children.
// const [ bind, { height } ] = useMeasure()
const useMeasure = () => {
  const ref = React.useRef()
  const [ bounds, set ] = React.useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ ro ] = React.useState(
    () => new ResizeObserver(([ entry ]) => {
      // Wrap requestAnimationFrame to avoid the error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        set(entry.contentRect)
      })
    })
  )
  React.useEffect(
    () => ref.current && ro.observe(ref.current) && ro.disconnect,
    [ ro ]
  )
  return [ { ref }, bounds ]
}

export default useMeasure
