import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import SmartLink from '../Primitive/SmartLink'
import Icon from '../Primitive/Icon'
import Button from '../Primitive/Button'
import OfferStatus from '../OfferStatus'

const OfferCard = ({
  theme,
  offer,
  size,
  onBookmark,
  isBookmarked,
  callToActionButtonText,
  partnerInBody
}) => {
  const {
    displayShortHeadline,
    shortHeadline,
    image,
    status,
    showStatus,
    partners = [],
    __fullUrlPath
  } = offer

  const partner = partners[0]

  const handleBookmark = (e) => {
    e.preventDefault()
    onBookmark && onBookmark(offer)
  }

  return (
    <div
      className={classNames(
        'OfferCard',
        theme && `OfferCard-${theme}`,
        isBookmarked && 'OfferCard--bookmarked',
        size && `OfferCard--${size}`
      )}
    >
      <SmartLink href={__fullUrlPath} className="OfferCard__Wrapper">
        <div className="OfferCard__Image">
          <img src={image.url} alt={shortHeadline} />
          {partner && !partnerInBody && (
            <img
              className="OfferCard__Partner"
              src={partner.logoUrl}
              alt={partner.name}
            />
          )}
          {onBookmark && (
            <SmartLink onClick={handleBookmark} className="OfferCard__Bookmark">
              <Icon
                width={20}
                height={20}
                type="Bookmark"
                ay11Text="Bookmark"
              />
            </SmartLink>
          )}
          {status && showStatus && <OfferStatus className="OfferCard__Status" status={status} />}
        </div>
        {partner && partnerInBody && (
          <img
            className="OfferCard__PartnerBody"
            src={partner.logoUrl}
            alt={partner.name}
          />
        )}
        <h2
          className="OfferCard__Title"
          dangerouslySetInnerHTML={{ __html: displayShortHeadline }}
        />
      </SmartLink>
      <Button className="OfferCard__Cta" href={__fullUrlPath} primary uppercase>
        {callToActionButtonText}
      </Button>
    </div>
  )
}

OfferCard.propTypes = {
  theme: PropTypes.string,
  onBookmark: PropTypes.func,
  isBookmarked: PropTypes.bool,
  partnerInBody: PropTypes.bool,
  size: PropTypes.string,
  callToActionButtonText: PropTypes.string,
  offer: PropTypes.shape({
    displayShortHeadline: PropTypes.string,
    shortHeadline: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string
    }),
    status: PropTypes.string,
    ctaLabel: PropTypes.string,
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        logoUrl: PropTypes.string
      })
    )
  })
}

export default withTheme(OfferCard)
