import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import Icon from '../Primitive/Icon'
import useMeasure from '../../lib/use-measure'
import { useSpring, animated } from 'react-spring'

const AboutBroadsheet = ({ theme, html, title }) => {
  const [ open, setOpen ] = useState(true)
  const [ bind, { height } ] = useMeasure()
  const handleToggle = () => {
    setOpen(!open)
  }
  return (
    <div
      className={classNames(
        'AboutBroadsheet',
        theme && `AboutBroadsheet-${theme}`,
        open && 'AboutBroadsheet--open'
      )}
    >
      <button className="AboutBroadsheet__Title" onClick={handleToggle}>
        {title || 'About Broadsheet Access'}
        <Icon
          type={open ? 'ChevronUp' : 'ChevronDown'}
          width={16}
          height={16}
          a11yText={open ? 'Close' : 'Open'}
        />
      </button>
      <animated.div
        className="AboutBroadsheet__About"
        style={useSpring({
          height: open ? height + 56 : 0
        })}
      >
        <div className="AboutBroadsheet__AboutInner" {...bind}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </animated.div>
    </div>
  )
}

AboutBroadsheet.propTypes = {
  theme: PropTypes.string,
  html: PropTypes.string,
  title: PropTypes.string
}

export default withTheme(AboutBroadsheet)
