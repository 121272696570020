import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'

const OfferTerms = ({ theme, terms, termsLink }) => {
  return (
    <div className={classNames('OfferTerms', theme && `OfferTerms-${theme}`)}>
      <p dangerouslySetInnerHTML={{ __html: terms }} />
      <a href={termsLink} target="_blank" className="OfferTerms__Heading">Terms and Conditions</a>
    </div>
  )
}

OfferTerms.propTypes = {
  theme: PropTypes.string,
  termsLink: PropTypes.string,
  terms: PropTypes.string
}

export default withTheme(OfferTerms)
