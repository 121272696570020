import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import JsBarcode from 'jsbarcode'

import withTheme from '../Theme/withTheme'
import Prose from '../Primitive/Prose'
import Button from '../Primitive/Button'

const RedemptionSummary = ({
  theme,
  title,
  subtitle,
  html,
  ctaText,
  secondaryCtaText,
  ctaLink,
  ctaOpensInNewWindow,
  children,
  link
}) => {
  useEffect(() => {
    if (typeof document === 'undefined') return
    const el = document.getElementById('unique-barcode')
    if (el) {
      const code = el.dataset.code
      JsBarcode(el, code)
    }
  }, [])

  return (
    <Fragment>
      <div
        className={classNames(
          'RedemptionSummary',
          theme && `RedemptionSummary-${theme}`
        )}
      >
        <h2 className="RedemptionSummary__Title">
          <span className="RedemptionSummaryTitle__Emphasised">{title}</span>
          {subtitle}
        </h2>
        {html && <Prose className="RedemptionSummary__Copy" html={html} />}
        {ctaLink && (secondaryCtaText || ctaText) && (
          <Button
            uppercase
            target={ctaOpensInNewWindow ? '_blank' : ''}
            primary
            href={ctaLink}
          >
            {secondaryCtaText || ctaText}
          </Button>
        )}
        {children}
      </div>
      {link && (
        <p className="RedemptionSummary__Link">
          <a href={link}>Visit the page</a> for full details of this Reward.
        </p>
      )}
    </Fragment>
  )
}

RedemptionSummary.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  ctaText: PropTypes.string,
  secondaryCtaText: PropTypes.string,
  ctaLink: PropTypes.string,
  html: PropTypes.string,
  ctaOpensInNewWindow: PropTypes.bool,
  link: PropTypes.string,
  children: PropTypes.node
}

export default withTheme(RedemptionSummary)
