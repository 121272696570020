import 'babel-polyfill'
import 'core-js/fn/number/parse-int'
import regg from 'regg'

import 'classlist-polyfill'
import 'element-closest'
import 'nodelist-foreach-polyfill'
import { EventEmitter } from 'events'
import config from '../whitelist-config.json'
import elementDataSet from 'element-dataset'

const env = process.env.NODE_ENV || 'development'
const serverEnv = process.env.SERVER_NODE_ENV || 'development'

const debug = require('debug')('base')
debug('Init')
elementDataSet()
debug('NODE_ENV', env)

const serviceLocator = regg()

window.Raven && window.Raven.config(config.sentry.site.dsn, {
  environment: env,
  ignoreErrors: [],
  whitelistUrls: [
    /localhost\.clockhosting\.com/,
    /staging-access\.broadsheet\.com\.au/,
    /access\.broadsheet\.com\.au/
  ]
}).install()

// Look for an encoded article
const encodedEntityNode = document.querySelector('#encoded-entity')
if (encodedEntityNode) {
  const entityJson = encodedEntityNode.innerHTML
  try {
    window.__entity = JSON.parse(entityJson)
  } catch (e) {
    window.Raven.captureException(e)
    console.log(e)
  }
}

const components = [
  require('./smooth-scroll').default,
  require('./vertical-scroll-states').default,
  require('./sticky/init').default,
  require('./toggler/init').default,
  require('./about-broadsheet/init').default,
  require('./offer-carousel/init').default,
  require('./hero/init').default,
  require('./offer-hero/init').default,
  require('./offer-sharing/init').default,
  require('./redemption-area/init').default,
  require('./quotes/init').default,
  require('./partner-carousel/init').default,
  require('./key-facts/init').default,
  require('./navigation/init').default,
  require('./cookie-policy/init').default,
  require('./user-banner/init').default,
  require('./braze-loader').default,
  require('./analytics/init').default
]

serviceLocator.instancePath = document.querySelector('body').dataset.instancePath
serviceLocator.subscribeUrl = document.querySelector('body').dataset.subscribeUrl
serviceLocator.config = config
serviceLocator.env = env
serviceLocator.serverEnv = serverEnv

window.messageBus = new EventEmitter()

components.forEach(component => component(serviceLocator, window))
