import React from 'react'
import PropTypes from 'prop-types'
import Content from '../Content'
import { Swiper, SwiperSlide } from 'swiper/react'

const QuoteBroadsheet = ({ quotes }) => {
  return (
    <div className="Quote Quote-broadsheet">
      <Content width="large" gutter center className="Quote__Inner">
        <Swiper
          className="Quote__Swiper"
          spaceBetween={24}
          slidesPerView="auto"
        >
          {quotes.map((item, i) => (
            <SwiperSlide className="Quote__Slide" key={`Quote-${i}`}>
              <blockquote className="Quote__Item">
                <p className="Quote__Text">{item.quote}</p>
                <cite className="Quote__Author">{item.author}</cite>
              </blockquote>
            </SwiperSlide>
          ))}
        </Swiper>
      </Content>
    </div>
  )
}

QuoteBroadsheet.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string
    })
  )
}

export default QuoteBroadsheet
