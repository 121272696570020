import React from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'
import SmartLink from '../SmartLink'

const Button = ({ theme, className, disabled, block, blockMobile, primary, secondary, uppercase, white, ...other }) => {
  return (
    <SmartLink
      className={classNames(
        'Button',
        theme && `Button-${theme}`,
        block && 'block',
        blockMobile && 'blockMobile',
        disabled && 'disabled',
        primary && 'primary',
        secondary && 'secondary',
        uppercase && 'uppercase',
        white && 'white',
        className
      )}
      disabled={disabled}
      {...other}
    />
  )
}

Button.displayName = 'Button'

Button.propTypes = {
  block: bool,
  blockMobile: bool,
  primary: bool,
  secondary: bool,
  uppercase: bool,
  white: bool,
  children: node.isRequired,
  className: string,
  disabled: bool,
  theme: string
}

export default withTheme(Button)
