/* eslint-disable no-tabs */
import React from 'react'
import PropTypes from 'prop-types'

class SevenRoomsRedemptionArea extends React.Component {
  componentDidMount () {
    const { redemptionTypeProperties } = this.props

    const script = document.createElement('script')
    script.innerHTML = `
      SevenroomsWidget.init({
        venueId: "${redemptionTypeProperties.venueID}",
        triggerId: "sr-res-root", // id of the dom element that will trigger this widget
        type: "reservations", // either 'reservations' or 'waitlist' or 'events'
        styleButton: false, // true if you are using the SevenRooms button
      })
    `

    document.head.appendChild(script)
  }

  render () {
    const { redemptionCtaText } = this.props

    return (
      <React.Fragment>
        <script src="https://www.sevenrooms.com/widget/embed.js" />
        <div
          id="sr-res-root"
          className="js-track-redemption sr-sm sr-dark sr-#bd0800 Button Button-broadsheet primary uppercase RedemptionButtonCta"
        >
          {redemptionCtaText || 'Book now'}
        </div>
      </React.Fragment>
    )
  }
}

SevenRoomsRedemptionArea.propTypes = {
  redemptionTypeProperties: PropTypes.object,
  redemptionCtaText: PropTypes.string
}

export default SevenRoomsRedemptionArea
