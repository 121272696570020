import createBrazeLoader from './loader'
import createUserTracker from './user-tracker'
import createIdentityChecker from './identity-checker'
import createRedemptionTracker from './redemption-tracker'

const debug = require('debug')('braze')

export default (serviceLocator) => {
  debug('init')

  document.addEventListener('DOMContentLoaded', async () => {
    window.__userInfo = window.__userInfo || {}

    const initBraze = createBrazeLoader(serviceLocator)
    const initUserTracking = createUserTracker()
    const getIdentity = createIdentityChecker(serviceLocator)
    const initRedemptionTracking = createRedemptionTracker(serviceLocator)

    const isBrazeInitialised = initBraze()

    try {
      const user = await getIdentity()
      const isLoggedIn = !!user

      if (!isBrazeInitialised || !isLoggedIn) {
        window.messageBus.emit('braze:initialised')
        return null
      }

      initUserTracking(user)
      initRedemptionTracking()
      window.messageBus.emit('braze:initialised')
    } catch (e) {
      console.error(e)
      window.messageBus.emit('braze:initialised')
    }
  })
}
