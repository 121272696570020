import React, { PureComponent } from 'react'
import { bool, node, oneOfType, string, array } from 'prop-types'

import CustomCheckControl from './CustomCheckControl'
import NativeCheckControl from './NativeCheckControl'
import ShrinkWrap, { ShrinkWrapItem } from '../../ShrinkWrap'

class CheckControl extends PureComponent {
  checkSelected (selected, value) {
    if (!selected) {
      return false
    }

    if (typeof selected === 'string') {
      return selected === value
    }

    let values = selected

    if (Array.isArray(values)) {
      values = new Set(values)
    }

    return values.has(value)
  }

  renderCheck (checkProps, label, key) {
    const CheckControlType = this.props.native ? NativeCheckControl : CustomCheckControl
    const isChecked = this.checkSelected(checkProps.selected, checkProps.value)
    return (
      <ShrinkWrap key={key} element="label" vAlign="top">
        <ShrinkWrapItem shrink>
          <CheckControlType {...checkProps} checked={isChecked} />
        </ShrinkWrapItem>
        <ShrinkWrapItem>{label}</ShrinkWrapItem>
      </ShrinkWrap>
    )
  }

  render () {
    const { children, options, type, label } = this.props

    if (options && options.length > 0) {
      return (
        <div>
          {options.map((option, i) => this.renderCheck(Object.assign({}, this.props, option), option.text, i))}
        </div>
      )
    }

    if (type === 'inlineCheckbox') {
      const props = Object.assign({}, this.props, { value: 'checked', type: 'checkbox' })
      return this.renderCheck(props, <span dangerouslySetInnerHTML={{ __html: label }} />)
    }

    return this.renderCheck(this.props, children)
  }
}

CheckControl.propTypes = 'CheckControl'

CheckControl.propTypes = {
  options: array,
  label: string,
  type: string,
  children: oneOfType([ string, node ]),
  native: bool
}

export default CheckControl
