import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Announcement from '../Announcement'
import { useSpring, animated } from 'react-spring'
import useMeasure from '../../lib/use-measure'

const getCtaProps = (isLoggedIn, subscribed) => {
  if (isLoggedIn && !subscribed) {
    return {
      ctaLink: '/sign-up',
      ctaText: 'Upgrade to access',
      ctaEventName: 'click_signup',
      ctaEventPosition: 'top_banner'
    }
  }

  if (!isLoggedIn) {
    return {
      ctaLink: '/sign-up',
      ctaText: 'Sign up',
      ctaEventName: 'click_signup',
      ctaEventPosition: 'top_banner'
    }
  }

  return {}
}

export const UserBanner = ({ isLoggedIn, firstName, subscribed }) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ bind, { height } ] = useMeasure()
  const styles = useSpring({
    height: isOpen ? height : 0
  })

  useEffect(() => {
    const hasDismissedSignedIn =
      window.localStorage.getItem('hasDismissedUserBannerSignedIn') === 'true'
    const hasDismissedSignedOut =
      window.localStorage.getItem('hasDismissedUserBannerSignedOut') === 'true'

    setTimeout(() => {
      if (!isLoggedIn && hasDismissedSignedIn) {
        window.localStorage.removeItem('hasDismissedUserBannerSignedIn')
      }

      if (isLoggedIn && hasDismissedSignedOut) {
        window.localStorage.removeItem('hasDismissedUserBannerSignedOut')
      }

      if (
        (isLoggedIn && !hasDismissedSignedIn) ||
        (!isLoggedIn && !hasDismissedSignedOut)
      ) {
        setIsOpen(true)
      }
    }, 1000)
  }, [])

  const onClose = () => {
    setIsOpen(false)
    if (isLoggedIn) {
      window.localStorage.setItem('hasDismissedUserBannerSignedIn', 'true')
    } else {
      window.localStorage.setItem('hasDismissedUserBannerSignedOut', 'true')
    }
  }

  return (
    <animated.div
      style={{
        ...styles,
        visibility: styles.height.interpolate((o) =>
          o === 0 ? 'hidden' : 'visible'
        )
      }}
    >
      <div {...bind}>
        <Announcement
          {...getCtaProps(isLoggedIn, subscribed)}
          onClose={onClose}
          className="UserBanner"
        >
          {isLoggedIn && subscribed && (
            <p>Hi {firstName}, welcome back to Broadsheet Access.</p>
          )}

          {isLoggedIn && !subscribed && (
            <p>
              Hi {firstName}, Upgrade to an Access account to unlock more of
              your city.
            </p>
          )}

          {!isLoggedIn && (
            <p>
              Welcome to Broadsheet Access. Unlock incredible experiences in your city today
            </p>
          )}
        </Announcement>
      </div>
    </animated.div>
  )
}

UserBanner.propTypes = {
  isLoggedIn: PropTypes.bool,
  firstName: PropTypes.string,
  subscribed: PropTypes.bool
}

export default UserBanner
