import trackEvent, { initialise } from './lib/track'
import delegate from 'delegate'

const debug = require('debug')('analytics')

export default () => {
  debug('init')
  window.messageBus.on('braze:initialised', () => {
    initialise()
    debug('initialised')
    // track landing on an offer page
    if (window.__entity) {
      debug('tracking offer page')
      trackEvent('view_offer', {
        url: window.location.href,
        category: window.__entity.category,
        slug: window.__entity.slug
      })
    }

    delegate(document, '.js-track-link', 'click', (e) => {
      debug('tracking link click')
      const link = e.delegateTarget

      // track clicks on links
      e.preventDefault()
      const href = link.getAttribute('href')
      const target = link.getAttribute('target')
      const { eventName, position } = link.dataset

      trackEvent(eventName, {
        url: window.location.href,
        position
      })

      setTimeout(() => {
        if (target === '_blank') {
          window.open(href, '_blank')
        } else {
          window.location.href = href
        }
      }, 300)
    })

    delegate(document, '.js-track-redemption', 'click', (e) => {
      debug('tracking link click')
      const link = e.delegateTarget

      e.preventDefault()
      const { eventName, position } = link.dataset

      trackEvent(eventName, {
        url: window.location.href,
        position
      })
    })

    if (document.querySelector('.js-fresh-login')) {
      debug('tracking fresh login')
      trackEvent('access_log_in', {})

      if (window.braze) {
        window.braze.logCustomEvent('access_log_in', {})
      }
    }
  })
}
