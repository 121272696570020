
export default function trackEvent (event, properties) {
  let track = console.log

  if (window.dataLayer && window.dataLayer.push) {
    track = window.dataLayer.push
  }

  track({
    event: 'logEvent',
    eventName: event,
    eventProperties: properties,
    userProperties: window.__userInfo || {}
  })
}

export function initialise () {
  let track = console.log

  if (window.dataLayer && window.dataLayer.push) {
    track = window.dataLayer.push
  }

  track({
    event: 'initialise',
    userProperties: window.__userInfo || {}
  })
}

export function trackRedemption (buttonText) {
  const brazeUser = window.braze.getUser()
  const isSevenRooms = window && window.__entity && window.__entity.isSevenRooms

  trackEvent('redeem_offer', {
    button_text: buttonText,
    category: window.__entity.category,
    slug: window.__entity.slug,
    url: window.location.href
  })

  if (window.braze) {
    if (isSevenRooms) {
      // Counts the number of tables booked
      window.braze.logCustomEvent('bs_table_booked', {})
      // Adds a last table booked with a slug for reference
      brazeUser.setCustomUserAttribute('last_broadsheet_table_booked', window.__entity.slug)
    }

    window.braze.logCustomEvent('redeem_offer', {
      button_text: buttonText,
      category: window.__entity.category,
      slug: window.__entity.slug,
      url: window.location.href
    })
  }
}
