import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'

const KeyFacts = ({ theme, title, items, offerId, stateData }) => {
  if (!items.length > 0) return null

  const calendarUrl = (index, type) =>
    `/calendar?id=${offerId}&index=${index}&type=${type}`
  return (
    <div className={classNames('KeyFacts', theme && `KeyFacts-${theme}`)}>
      {title && <h2 className="KeyFacts__Title">{title}</h2>}
      <div className="KeyFacts__Items">
        {items.map((item, i) => (
          <div key={`KeyFacts__Item-${i}`} className="KeyFacts__Item">
            {item.key && <h3 className="KeyFacts__ItemKey">{item.key}</h3>}
            <div
              dangerouslySetInnerHTML={{ __html: item.value }}
              className="KeyFacts__ItemValue"
            />
            {theme === 'broadsheet' && item.startDate && (
              <div className="KeyFacts__LinkWrapper">
                <a className="js-toggle-switch">+ Add to calendar</a>
                <div className="js-toggle-content is-hidden">
                  <a
                    target="_blank"
                    className="KeyFacts__Link"
                    href={calendarUrl(i, 'ical')}
                  >
                    iCal Calendar
                  </a>
                  <a
                    target="_blank"
                    className="KeyFacts__Link"
                    href={calendarUrl(i, 'gcal')}
                  >
                    Google Calendar
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

KeyFacts.propTypes = {
  offerId: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  stateData: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object),
}

export default withTheme(KeyFacts)
