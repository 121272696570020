import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import RedemptionSummary from '../../../../layout/component/RedemptionSummary'
import Button from '../../../../layout/component/Primitive/Button'
import LoadingSpinner from '../../../../layout/component/Primitive/LoadingSpinner'
import Notification from '../../../../layout/component/Primitive/Notification'
import Icon from '../../../../layout/component/Primitive/Icon'
import FormBuilder from '../../../../layout/component/FormBuilder/FormBuilder'
import PurchaseForm from './PurchaseForm'
import FormOverview from './FormOverview'
import FieldTemplate from '../../../../layout/component/Primitive/FieldTemplate'
import ResendEmail from './ResendEmail'

const PurchaseRedemptionArea = (props) => {
  const {
    formData,
    prePopulateData,
    submitForm,
    thankYouData,
    authKey,
    submissionSuccess,
    paymentSuccess,
    paymentFailed,
    redemptionTypeProperties,
    changeDetailsOnClick,
    waiting,
    hasPastPurchase
  } = props
  const ctaText = 'Proceed to Payment'
  if (formData) formData.ctaText = ctaText

  const PaymentAmount = () => {
    return (
      <FieldTemplate
        label="Payment amount"
        id="amount"
      >
        <p>${((redemptionTypeProperties.cost + redemptionTypeProperties.postageAndPackaging) / 100).toFixed(2)}</p>
      </FieldTemplate>
    )
  }

  return (
    <Fragment>
      <FormBuilder
        ctaText={ctaText}
        submissionSuccess={submissionSuccess}
        form={formData}
        data={prePopulateData}
        onSubmit={submitForm}
        PostFormComponent={PaymentAmount}
        {...props}>

        <Fragment>
          {!paymentSuccess && <FormOverview {...props} />}
          {!paymentSuccess &&
            <div className="FormBuilderPaymentInstructions">
              {waiting ? <LoadingSpinner />
                : (
                  !paymentSuccess && <Button secondary onClick={changeDetailsOnClick}>Change Details</Button>
                )
              }
              <Notification>
                <h3 className="NotficiationHeader">
                  <Icon type="InfoCircle" width={16} />
                  You have 10 minutes to complete your check out.
                </h3>
                <p>Please do not refresh your page (doing so will prevent you from purchasing for 10 minutes).</p>
              </Notification>
            </div>
          }
          {!paymentSuccess && <PaymentAmount />}
          <PurchaseForm authKey={authKey} paymentSuccess={paymentSuccess} paymentFailed={paymentFailed}>
            <RedemptionSummary {...thankYouData}>
              {hasPastPurchase && <ResendEmail {...props} />}
            </RedemptionSummary>
          </PurchaseForm>
        </Fragment>
      </FormBuilder>
      {hasPastPurchase && !paymentSuccess && <ResendEmail displayText {...props} />}
    </Fragment>
  )
}

PurchaseRedemptionArea.propTypes = {
  formData: PropTypes.object,
  prePopulateData: PropTypes.object,
  authKey: PropTypes.string,
  thankYouData: PropTypes.object,
  submissionSuccess: PropTypes.bool,
  paymentSuccess: PropTypes.bool,
  paymentFailed: PropTypes.bool,
  waiting: PropTypes.bool,
  submitForm: PropTypes.func,
  changeDetailsOnClick: PropTypes.func,
  redemptionTypeProperties: PropTypes.object,
  hasPastPurchase: PropTypes.bool
}

export default PurchaseRedemptionArea
