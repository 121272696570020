import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import Button from '../Primitive/Button'
import IconButton from '../Primitive/IconButton'
import Content from '../Primitive/Content'

const Announcement = ({
  theme,
  children,
  ctaLink,
  ctaText,
  ctaEventName,
  ctaEventPosition,
  onClose,
  className,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'Announcement',
        theme && `Announcement-${theme}`,
        className
      )}
      {...rest}
    >
      <Content className="Announcement__Content" width="large" gutter center>
        {children}
        {ctaLink && ctaText && (
          <Button
            white
            uppercase
            className="Announcement__Cta js-track-link"
            href={ctaLink}
            data-event-name={ctaEventName}
            data-position={ctaEventPosition}
          >
            {ctaText}
          </Button>
        )}
        <IconButton
          small
          className="Announcement__Close"
          icon="Close"
          onClick={onClose}
        />
      </Content>
    </div>
  )
}

Announcement.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.node,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  ctaEventName: PropTypes.string,
  ctaEventPosition: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string
}

export default withTheme(Announcement)
