const debug = require('debug')('braze')

const createUserTracker = () => {
  const trackUser = async (user) => {
    if (!user || !user.id) return null

    debug('Providing Braze with current user details')

    const { lastLoginDate, numberOfLogins, firebaseId, isEligible } = user

    debug(`Changing user to ${firebaseId}`)
    window.braze.changeUser(firebaseId)

    const brazeUser = window.braze.getUser()

    debug(`Setting attribute "last_login_date" to "${lastLoginDate}"`)
    debug(`Setting attribute "number_of_logins" to "${numberOfLogins}"`)
    debug(`Setting attribute "access_subscribed" to "${isEligible}"`)

    brazeUser.setCustomUserAttribute('last_login_date', lastLoginDate)
    brazeUser.setCustomUserAttribute('number_of_logins', numberOfLogins)
    brazeUser.setCustomUserAttribute('access_subscribed', isEligible)

    window.__userInfo.id = firebaseId
    window.__userInfo.userId = user.id
    window.__userInfo.last_login_date = lastLoginDate
    window.__userInfo.number_of_logins = numberOfLogins
    window.__userInfo.access_subscribed = isEligible
  }

  return trackUser
}

export default createUserTracker
