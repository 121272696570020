import React from 'react'
import PropTypes from 'prop-types'
import withTheme from '../../../component/Theme/withTheme'
import QuoteBroadsheet from './QuoteBroadsheet'

const Quote = ({ theme, ...other }) => {
  const Component = {
    broadsheet: <QuoteBroadsheet {...other} />
  }

  return Component[theme]
}

Quote.propTypes = {
  theme: PropTypes.string,
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string
    })
  )
}

export default withTheme(Quote)
