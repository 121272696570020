import React, { Component } from 'react'
import UmbrellaChildOffer from '../../../site/layout/component/UmbrellaChildOffer'
import PropTypes from 'prop-types'
import RedemptionSummary from '../../../site/layout/component/RedemptionSummary'
import BasicRedemptionArea from './BasicRedemptionArea'
import PurchaseRedemptionArea from './PurchaseRedemptionArea'
import performRedemption from './redemption-submitter'

class UmbrellaChildComponent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      redeemClicked: props.redemptionInfoLoaded,
      type: props.redemptionInfoLoaded ? 'basic' : '',
      thankYouData: this.props.thankYouData || {}
    }

    this.redeemOffer = this.redeemOffer.bind(this)

    this.apiRoute = 'redemption'
    this.errorMessage = 'redeeming basic offer'
  }

  async redeemOffer () {
    const { formData, offer, redemptionInfoLoaded } = this.props
    if (formData && !redemptionInfoLoaded) {
      this.setState({ type: 'form', offerId: offer._id })
    } else if (!formData && !redemptionInfoLoaded) {
      const data = {
        formId: null,
        formData: this.state.data
      }
      const { instancePath } = this.props
      const result = await performRedemption(data, instancePath, offer._id, this.apiRoute, this.errorMessage)
      this.setState({ type: 'basic', ...result })
    }
    this.setState({ redeemClicked: true })
  }

  componentDidMount () {
    window.messageBus.on('disableOthers', () => {
      if (this.props.state === 'redeemable') {
        this.setState({ currentState: 'limitReached' })
      }
    })
    window.messageBus.on('enableOthers', () => {
      if (this.props.state === 'redeemable') {
        this.setState({ currentState: undefined })
      }
    })
  }

  render () {
    const { offer, timezone, redemptionType, state, formData } = this.props
    const { redeemClicked, type, thankYouData, currentState } = this.state

    let Area = 'div'
    if (redemptionType === 'purchase') {
      Area = PurchaseRedemptionArea
    } else {
      Area = BasicRedemptionArea
    }

    return (
      <UmbrellaChildOffer
        {...offer}
        timezone={timezone}
        redeemClick={this.redeemOffer}
        redeemClicked={redeemClicked}
        state={currentState || state}
        redemptionData={{ thankYouData, formData }}
      >
        {redeemClicked && (
          <div>
            {type === 'basic' && (
              <RedemptionSummary {...thankYouData} />
            )}
            {type === 'form' && (
              <Area {...this.props} {...this.state} />
            )}
          </div>
        )}
      </UmbrellaChildOffer>
    )
  }
}

UmbrellaChildComponent.propTypes = {
  formData: PropTypes.object,
  redemptionInfoLoaded: PropTypes.bool,
  thankYouData: PropTypes.object,
  redemptionType: PropTypes.string,
  timezone: PropTypes.string,
  offer: PropTypes.object,
  instancePath: PropTypes.string,
  state: PropTypes.string
}

export default UmbrellaChildComponent
