const debug = require('debug')('braze')

export default (questions, responses) => {
  if (!questions || !questions.length || !responses || !responses.formData) return false

  questions.forEach(function (question, index) {
    const brazeAttribute = question.brazeAttribute
    const hasBrazeAttribute = !!brazeAttribute

    if (!hasBrazeAttribute) return false

    const value = responses.formData[`question-${index}`]

    if (!value) return false

    const brazeUser = window.braze.getUser()

    // Brave requires the keys to be lowercase + spaces must be replaced with underscores
    const sanitisedKey = brazeAttribute.replace(/ /g, '_').toLowerCase()

    debug(`Setting attribute "${sanitisedKey}" to "${value}"`)

    brazeUser.setCustomUserAttribute(sanitisedKey, value)
  })
}
