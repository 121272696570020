import schemata from 'schemata'
import required from 'validity-required'
import isEmail from 'validity-email'
import ifSet from 'validity-validate-if-set'
import validateWordLength from './lib/word-length-validator'
import validateDate from './lib/date-validator'
import validateAddress from './lib/address-validator'
import validateCodeWord from './lib/code-word-validator'

const createSchema = (questions, parentId, basePath) => {
  if (!questions) return schemata({})
  const formResponseSchema = questions.reduce((schema, question, i) => {
    schema[`question-${i}`] = {}
    schema[`question-${i}`].validators = []
    if (question.required) {
      schema[`question-${i}`].validators.push(required.setFailureMessage('This is required'))
    }
    if (question.inputType === 'email') {
      schema[`question-${i}`].validators.push(
        ifSet(isEmail.setFailureMessage('Must be a valid email'))
      )
    }

    if (question.inputType === 'datePicker') {
      schema[`question-${i}`].validators.push(
        ifSet(validateDate)
      )
    }

    if (question.inputType === 'textarea' && question.wordLimit > 0) {
      const wordLength = validateWordLength(question.wordLimit)
      schema[`question-${i}`].validators.push(
        ifSet(wordLength)
      )
    }

    if (question.inputType === 'address') {
      schema[`question-${i}`].validators.push(
        ifSet(validateAddress)
      )
    }

    if (question.inputType === 'codeWord') {
      const codeWord = validateCodeWord(parentId, basePath)
      schema[`question-${i}`].validators.push(
        ifSet(codeWord)
      )
    }
    return schema
  }, {})
  return schemata(formResponseSchema)
}

export default createSchema
