import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import { Swiper, SwiperSlide } from 'swiper/react'
import OfferCard from '../OfferCard'
import Icon from '../Primitive/Icon'
import Content from '../Primitive/Content'

import useMeasure from '../../lib/use-measure'
import { useSpring, animated } from 'react-spring'

const OfferCarousel = ({
  theme,
  offers,
  title,
  subtitle,
  showLargeCarousel,
  callToActionButtonText,
  className
}) => {
  const [ open, setOpen ] = useState(true)
  const [ bind, { height } ] = useMeasure()
  const handleToggle = () => {
    setOpen(!open)
  }

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  return (
    <div
      className={classNames(
        'OfferCarousel',
        theme && `OfferCarousel-${theme}`,
        showLargeCarousel && 'OfferCarousel--desktopCarousel',
        className
      )}
    >
      <Content className="OfferCarousel__Content" width="large" gutter center>
        <div className="OfferCarousel__TitleWrapper">
          {subtitle ? (
            <div className={classNames(open && `${title}--open`)}>
              <button className="OfferCarousel__Title" onClick={handleToggle}>
                {title}
                <Icon
                  type={open ? 'ChevronUp' : 'ChevronDown'}
                  width={16}
                  height={16}
                  a11yText={open ? 'Close' : 'Open'}
                />
              </button>
              <animated.div
                className="OfferCarousel__About"
                style={useSpring({
                  height: open ? height + 20 : 0
                })}
              >
                <div className="OfferCarousel__AboutInner" {...bind}>
                  <div className="OfferCarousel__AboutTitle">
                    <div dangerouslySetInnerHTML={{ __html: subtitle }} />
                  </div>
                </div>
              </animated.div>
            </div>
          ) : (
            <h2 className="OfferCarousel__Title">{title}</h2>
          )}
          <div className="OfferCarousel__Navigation">
            <button className="OfferCarousel__Button" ref={prevRef}>
              <Icon width={20} height={20} type="ChevronLeft" />
            </button>
            <button className="OfferCarousel__Button" ref={nextRef}>
              <Icon width={20} height={20} type="ChevronRight" />
            </button>
          </div>
        </div>
        <Swiper
          className="OfferCarousel__Swiper"
          spaceBetween={24}
          slidesPerView={1}
          slidesPerGroup={1}
          threshold={1}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          breakpoints={{
            550: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            1024: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
          }}
        >
          {offers &&
            offers.length > 0 &&
            offers.map((offer) => (
              <SwiperSlide className="OfferCarousel__Slide" key={offer._id}>
                <OfferCard
                  offer={offer}
                  size={showLargeCarousel ? 'large' : null}
                  callToActionButtonText={callToActionButtonText}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </Content>
    </div>
  )
}

OfferCarousel.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showLargeCarousel: PropTypes.bool,
  callToActionButtonText: PropTypes.string,
  className: PropTypes.string,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      theme: PropTypes.string,
      offer: PropTypes.shape({
        displayShortHeadline: PropTypes.string,
        shortHeadline: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string
        }),
        category: PropTypes.string,
        status: PropTypes.string,
        ctaLabel: PropTypes.string,
        partners: PropTypes.shape({
          name: PropTypes.string,
          logoUrl: PropTypes.string
        })
      })
    })
  )
}

export default withTheme(OfferCarousel)
