import createDataFetcher from './data-fetcher'

const debug = require('debug')('braze')

export default (serviceLocator) => async () => {
  const fetchData = createDataFetcher(serviceLocator)
  const userData = await fetchData('identity')
  const isLoggedIn = !!(userData && userData.id)

  debug(`User is ${isLoggedIn ? '' : 'not '}logged-in`)

  return isLoggedIn ? userData : null
}
