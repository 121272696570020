import createDataFetcher from './data-fetcher'

const debug = require('debug')('braze')

const createRedemptionTracker = (serviceLocator) => {
  const trackRedemptions = async () => {
    const brazeUser = window.braze.getUser()
    const fetchData = createDataFetcher(serviceLocator)
    const stats = await fetchData('redemption/get-user-stats')

    debug(`Fetching redemption stats for the current user`)

    if (!stats) return null

    const { offersRedeemed, lastOfferRedeemedDate } = stats
    const lastOfferedRedeemedDate = new Date(lastOfferRedeemedDate)

    debug(`Setting attribute "offers_redeemed" to "${offersRedeemed}"`)
    debug(`Setting attribute "last_offered_redeemed_date" to "${lastOfferRedeemedDate}"`)

    brazeUser.setCustomUserAttribute('offers_redeemed', offersRedeemed)
    brazeUser.setCustomUserAttribute('last_offered_redeemed_date', lastOfferedRedeemedDate)

    window.__userInfo.offers_redeemed = offersRedeemed
    window.__userInfo.last_offer_redeemed_date = lastOfferRedeemedDate
  }

  return trackRedemptions
}

export default createRedemptionTracker
