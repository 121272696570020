const debug = require('debug')('braze')

export default (serviceLocator) => () => {
  const env = serviceLocator.env
  const config = serviceLocator.config

  if (!env || !config) return false

  try {
    window.braze.initialize(config.brazeSdk.key, {
      baseUrl: config.brazeSdk.endpoint,
      enableLogging: env === 'development'
    })
    window.braze.automaticallyShowInAppMessages()
    window.braze.openSession()
  } catch (err) {
    debug(`[L] Error: ${err.message}`)

    if (window.Raven) {
      window.Raven.captureException(new Error(`Error: ${err.message}`))
    } else {
      console.error(err, err.message)
    }

    return false
  }

  debug('Successfully started the Braze session.')

  return true
}
