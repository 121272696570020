import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '../Icon'

import useEscapeKey from '../../../lib/use-escape-key'
import useOutsideClick from '../../../lib/use-outside-click'
import withTheme from '../../Theme/withTheme'
import useMeasure from '../../../lib/use-measure'
import { useSpring, animated } from 'react-spring'
import SmartLink from '../SmartLink'

const Dropdown = ({ theme, onClick, className, items, iconSize = 14 }) => {
  const [ open, setOpen ] = useState(false)
  const ref = useRef()
  const [ bind, { height } ] = useMeasure()

  const handleClose = () => {
    open && setOpen(false)
  }

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleSelect = (item) => {
    onClick(item)
    setOpen(false)
  }

  useEscapeKey(handleClose)
  useOutsideClick(ref, open, handleClose)

  return (
    <div
      ref={ref}
      className={classnames(
        'Dropdown',
        theme && `Dropdown-${theme}`,
        className
      )}
    >
      <button onClick={handleToggle} className="Dropdown__Label">
        Menu
        <Icon
          type={open ? 'ChevronUp' : 'ChevronDown'}
          width={iconSize}
          height={iconSize}
          a11yText={open ? 'Close' : 'Open'}
        />
      </button>
      <animated.div
        className="Dropdown__Items"
        style={useSpring({
          height: open ? height + 20 : 0
        })}
      >
        <div className="Dropdown__ItemsInner" {...bind}>
          {items &&
          items.length > 0 &&
          items.map((item) => (
            <SmartLink
              href={item.url ? item.url : null}
              key={`DropdownItem-${item.label}`}
              className="Dropdown__Item"
              {...(onClick ? { onClick: () => handleSelect(item) } : {})}
            >
              {item.label}
            </SmartLink>
          ))}
        </div>
      </animated.div>
    </div>
  )
}
export default withTheme(Dropdown)

Dropdown.propTypes = {
  theme: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.object,
  className: PropTypes.string,
  iconSize: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  )
}
