import React from 'react'
import PropTypes from 'prop-types'
import withTheme from '../Theme/withTheme'
import Icon from '../Primitive/Icon'
import classNames from 'classnames'

const OfferStatus = ({ status, theme, className }) => {
  return (
    <div className={classNames('OfferStatus', `OfferStatus-${theme}`, className)}>
      <div className="OfferStatus__Status">{status}</div>
      <Icon width={32} type="StatusClip" className="OfferStatus__StatusClip" />
    </div>
  )
}

OfferStatus.propTypes = {
  status: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string
}

export default withTheme(OfferStatus)
