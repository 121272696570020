import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SevenRoomsRedemptionArea from '../../../site/widgets/offer-layout/redemption-area/component/SevenRoomsRedemptionArea'
import performRedemption from './redemption-submitter'

class SevenRoomsComponent extends Component {
  constructor (props) {
    super(props)
    this.handler = this.handler.bind(this)
  }

  async handler (ev) {
    if (ev.origin === 'https://www.sevenrooms.com' && ev.data.type !== 'heightEvent') {
      await performRedemption(
        ev.data,
        this.props.instancePath,
        this.props.offerId,
        'redemption',
        this.props.redemptionCtaText
      )
    }
  }

  componentDidMount () {
    window.addEventListener('message', this.handler)
  }
  componentWillUnmount () {
    window.removeEventListener('message', this.handler)
  }
  render () {
    const { redemptionTypeProperties, redemptionCtaText } = this.props
    return (
      <SevenRoomsRedemptionArea
        redemptionCtaText={redemptionCtaText}
        redemptionTypeProperties={redemptionTypeProperties}
      />
    )
  }
}

SevenRoomsComponent.propTypes = {
  instancePath: PropTypes.string,
  offerId: PropTypes.string,
  redemptionTypeProperties: PropTypes.object,
  redemptionCtaText: PropTypes.string
}

export default SevenRoomsComponent
