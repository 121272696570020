import React, { PureComponent } from 'react'
import { bool, func, number, oneOf, oneOfType, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../../Theme/withTheme'

export class TextControl extends PureComponent {
  constructor () {
    super()

    this.state = {
      showFauxPlaceholder: true
    }

    this.customFocus = this.customFocus.bind(this)
    this.customBlur = this.customBlur.bind(this)
  }

  componentDidMount () {
    this.setState({ showFauxPlaceholder: this.props.value === '' })
  }

  customFocus () {
    this.setState({ showFauxPlaceholder: false })
  }

  customBlur () {
    this.setState({ showFauxPlaceholder: !this.props.value && true })
  }

  render () {
    const {
      autoComplete,
      autoFocus,
      className,
      defaultValue,
      disabled,
      id,
      innerRef,
      maxLength,
      minLength,
      multiline,
      name,
      onBlur,
      onChange,
      onFocus,
      pattern,
      placeholder,
      readOnly,
      required,
      rows,
      size,
      status,
      tabIndex,
      theme,
      type,
      value
    } = this.props

    const componentClassName = classNames(
      'TextControl',
      theme && `TextControl-${theme}`,
      multiline && 'multiline',
      status && status,
      value.length > 0 && `TextControl-${theme}--typing`,
      className
    )

    const TextControlEl = multiline ? 'textarea' : 'input'

    return (
      <div className={classNames('TextControlWrapper', `TextControlWrapper-${theme}`)}>
        <TextControlEl
          aria-invalid={status === 'error' || undefined}
          aria-required={required}
          className={componentClassName}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
          onBlur={placeholder ? this.customBlur : onBlur}
          onChange={onChange}
          onFocus={placeholder ? this.customFocus : onFocus}
          pattern={pattern}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          ref={innerRef}
          rows={multiline && rows}
          size={size}
          tabIndex={tabIndex}
          type={!multiline ? type : undefined}
          value={value}
        />
        {this.state.showFauxPlaceholder && (
          <div
            className={classNames('TextControlPlaceholder', required && `TextControlPlaceholder--required`)}
            dangerouslySetInnerHTML={{ __html: placeholder }}
          />
        )}
      </div>
    )
  }
}

TextControl.displayName = 'TextControl'

TextControl.defaultProps = {
  type: 'text',
  rows: 3
}

TextControl.propTypes = {
  autoComplete: oneOfType([ bool, string ]),
  autoFocus: bool,
  className: string,
  defaultValue: string,
  disabled: bool,
  id: string,
  innerRef: func,
  maxLength: number,
  minLength: number,
  multiline: bool,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  pattern: string,
  placeholder: string,
  readOnly: bool,
  required: bool,
  rows: number,
  size: number,
  status: oneOf([ 'error', 'notice', 'success', 'warning' ]),
  tabIndex: number,
  theme: string,
  type: string,
  value: string
}

export default withTheme(TextControl)
