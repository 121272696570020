import { useEffect } from 'react'

// useOutsideClick(ref, handleClose)
const useOutsideClick = (reference, enabled, callback) => {
  useEffect(() => {
    if (typeof callback !== 'function') return
    if (!enabled) return
    const handleClick = (e) => {
      e.preventDefault()
      if (reference.current && !reference.current.contains(e.target)) {
        callback(e)
      }
    }

    document.addEventListener('mousedown', handleClick)
    document.addEventListener('touchstart', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('touchstart', handleClick)
    }
  }, [ reference, enabled, callback ])
}

export default useOutsideClick
