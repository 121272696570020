import React from 'react' // eslint-disable-line no-unused-vars
// Sourced from https://material.io/tools/icons/?style=outline
// svgo ./components/site/layout/component/Primitive/Icon/source --enable=removeXMLNS --enable=removeDimensions --enable=removeTitle --enable=removeStyleElement --multipass --pretty --indent=2

// cache-buster: 150

import TestSvg from './source/broadsheet/__test.svg'
import AccessLogo from './source/broadsheet/access-logo.svg'
import BroadsheetMedia from './source/broadsheet/broadsheet-media.svg'
import ChevronDown from './source/broadsheet/chevron--down.svg'
import ChevronUp from './source/broadsheet/chevron--up.svg'
import ChevronLeft from './source/broadsheet/chevron--left.svg'
import ChevronRight from './source/broadsheet/chevron--right.svg'
import Bookmark from './source/broadsheet/bookmark.svg'
import StatusClip from './source/broadsheet/status-clip.svg'
import BroadsheetLogo from './source/broadsheet/broadsheet-logo.svg'
import BroadsheetStamp from './source/broadsheet/broadsheet-stamp.svg'
import Close from './source/broadsheet/close.svg'
import Instagram from './source/broadsheet/instagram.svg'
import Facebook from './source/broadsheet/facebook.svg'
import Twitter from './source/broadsheet/twitter.svg'
import Share from './source/broadsheet/share.svg'

const Svgs = {
  AccessLogo,
  BroadsheetMedia,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  ChevronLeft,
  Bookmark,
  StatusClip,
  BroadsheetLogo,
  BroadsheetStamp,
  Close,
  Instagram,
  Facebook,
  Share,
  Twitter,
  TestSvg
}

export default Svgs
