import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper'
import Button from '../Primitive/Button'
import OfferStatus from '../OfferStatus'

SwiperCore.use([ Pagination, Autoplay, Navigation ])

const Hero = ({ theme, offers, mainImages, welcomeText }) => {
  const showLogoSlide = offers.length === 0

  return (
    <div className={classNames('Hero', theme && `Hero-${theme}`)}>
      { showLogoSlide ? <LogoSlide mainImages={mainImages} welcomeText={welcomeText} /> : (
        <Swiper
          className="Hero__Swiper"
          spaceBetween={0}
          slidesPerView={1}
          autoplay
          pagination={{ clickable: true }}
          enabled={offers.length > 0}
          navigation
          threshold={1}
        >
          <SwiperSlide>
            <LogoSlide mainImages={mainImages} welcomeText={welcomeText} />
          </SwiperSlide>
          {offers &&
          offers.length > 0 &&
          offers.map((offer, i) => (
            <SwiperSlide key={`Swiper-${i}`}>
              <OfferSlide {...offer} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

Hero.propTypes = {
  theme: PropTypes.string,
  offers: PropTypes.array.isRequired,
  mainImages: PropTypes.shape({
    url: PropTypes.string,
    mobileUrl: PropTypes.string
  }),
  welcomeText: PropTypes.array
}

export default withTheme(Hero)

const LogoSlide = ({ mainImages, welcomeText }) => (
  <Fragment>
    <picture className="Hero__Background">
      <source media="(min-width: 550px)" srcSet={mainImages.url} />
      <img src={mainImages.mobileUrl} alt="Broadsheet" />
    </picture>
    <div className="Hero__Content">
      <h1 className="Hero__LogoTitle">
        <span>{welcomeText[0]}</span>
        <span className="Hero__LogoTitle--emphasized">{welcomeText[1]}</span>
        <span>
          {welcomeText[2]}
        </span>
        <span className="Hero__LogoTitle--emphasized">{welcomeText[3]}</span>
      </h1>
    </div>
  </Fragment>
)

LogoSlide.propTypes = {
  mainImages: PropTypes.shape({
    url: PropTypes.string,
    mobileUrl: PropTypes.string
  }),
  welcomeText: PropTypes.array
}

const OfferSlide = ({
  displayShortHeadline,
  shortHeadline,
  status,
  showStatus,
  image,
  __fullUrlPath
}) => {
  return (
    <div className="Hero__OfferSlide">
      <picture className="Hero__Background">
        <source media="(min-width: 768px)" srcSet={image.url} />
        <img src={image.mobileUrl} alt={shortHeadline} />
      </picture>
      <div className="Hero__Content">
        <h2
          className="Hero__OfferSlide__Headline"
          dangerouslySetInnerHTML={{ __html: displayShortHeadline }}
        />
        <Button uppercase href={__fullUrlPath} white>
          Redeem
        </Button>
        { showStatus && <OfferStatus status={status} className="Hero__OfferSlide__Status" /> }
      </div>
    </div>
  )
}

OfferSlide.propTypes = {
  displayShortHeadline: PropTypes.string.isRequired,
  shortHeadline: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  showStatus: PropTypes.bool.isRequired,
  image: PropTypes.object.isRequired,
  __fullUrlPath: PropTypes.string.isRequired
}
